import React from "react";
import { useSelector } from "react-redux";
import { selectData } from "../pages/homeSlice";
import styled from "styled-components";
// Icons
import { FaGithub, FaLinkedinIn } from "react-icons/fa";
import { linkedInUrl } from "../data";

const StyledSocialLinks = styled.div`
    a {
        margin: 0 1rem;

        &:hover {
            color: white;
        }
    }
`;

export default function SocialLinks() {
    const { html_url } = useSelector(selectData);

    return (
        <StyledSocialLinks>
            <a
                href={html_url}
                aria-label="Check out my GitHub profile."
                className="link-icons"
            >
                <FaGithub />
            </a>
            <a
                href={linkedInUrl}
                aria-label="Check out my LinkedIn profile"
                className="link-icons"
            >
                <FaLinkedinIn />
            </a>
        </StyledSocialLinks>
    );
}
