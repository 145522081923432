import { useAppContext } from "../appContext";
import { Link } from "react-scroll";
// Components
import { Container, Nav, Navbar } from "react-bootstrap";
import { FixedNavSpacer } from "./globalStyledComponents";
import SocialLinks from "./SocialLinks"

export default function NavBar() {
    const { isExpanded, closeExpanded, toggleExpanded } = useAppContext();

    return (
        <>
            <FixedNavSpacer />
            <Navbar
                id="nav"
                collapseOnSelect={true}
                expand="lg"
                expanded={isExpanded}
                bg="dark"
                variant="dark"
                fixed="top"
            >
                <Container>
                    <span className="display-6 title me-5">CR</span>
                    <Navbar.Toggle
                        aria-controls="responsive-navbar-nav"
                        onClick={toggleExpanded}
                    />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav navbarScroll className="me-auto">
                            <Nav.Item>
                                <Link
                                    to={"Home"}
                                    spy={true}
                                    activeClass="active"
                                    className="nav-link"
                                    onClick={closeExpanded}
                                >
                                    About Me
                                </Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link
                                    to={"Projects"}
                                    spy={true}
                                    activeClass="active"
                                    className="nav-link"
                                    onClick={closeExpanded}
                                >
                                    Projects
                                </Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link
                                    to={"Skills"}
                                    spy={true}
                                    activeClass="active"
                                    className="nav-link"
                                    onClick={closeExpanded}
                                >
                                    Skills
                                </Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link
                                    to={"Contact"}
                                    spy={true}
                                    activeClass="active"
                                    className="nav-link"
                                    onClick={closeExpanded}
                                >
                                    Contact
                                </Link>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                    <SocialLinks />
                </Container>
            </Navbar>
        </>
    );
}
