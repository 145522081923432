import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGitHubInfo, selectError, selectIsLoading } from "./pages/homeSlice";
import { fetchGitHubRepos } from "./pages/allProjectsSlice";
import { HashRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
// Components
import { Container } from "react-bootstrap";
import { Loading } from "./components/globalStyledComponents";
import ScrollToTop from "./components/ScrollToTop";
import GlobalStyles from "./components/GlobalStyles";
// Pages
import Home from "./pages/Home";
import AllProjects from "./pages/AllProjects";
import NotFound from "./pages/NotFound";

const theme = {
    name: "dark",
    color: "#FBFDFF",
    background: "#27272A",
};

export default function App() {
    const isLoading = useSelector(selectIsLoading);
    const error = useSelector(selectError);
    const dispatch = useDispatch();

    useEffect(
        function () {
            dispatch(fetchGitHubInfo());
            dispatch(fetchGitHubRepos());
        },
        [dispatch]
    );

    if (isLoading) {
        return (
            <ThemeProvider theme={theme}>
                <GlobalStyles />
                <Container className="d-flex vh-100 align-items-center">
                    <Loading />
                </Container>
            </ThemeProvider>
        );
    } else if (error) {
        return (
            <ThemeProvider theme={theme}>
                <GlobalStyles />
                <Container className="d-flex vh-100 align-items-center justify-content-center">
                    <h2>{error}</h2>
                </Container>
            </ThemeProvider>
        );
    } else {
        return (
            <HashRouter>
                <ThemeProvider theme={theme}>
                    <ScrollToTop />
                    <GlobalStyles />
                    <Routes>
                        <Route exact path="/" element={<Home />} />
                        <Route path="/All-Projects" element={<AllProjects />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </ThemeProvider>
            </HashRouter>
        );
    }
}
