import { useSelector } from "react-redux";
import { selectData } from "../pages/homeSlice";
import styled from "styled-components";
// Media
import { moreInfo, backgroundImage } from "../data";
// Components
import { Col, Container, Row } from "react-bootstrap";
import { Spin } from "./globalStyledComponents";
import SocialLinks from "./SocialLinks";

const StyledAboutMe = styled.header`
    position: relative;
    display: grid;
    place-items: center;
    max-width: 1920px;
    margin: 0 auto;
    min-height: calc(100vh - var(--nav-height));

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: ${({ theme }) =>
        theme.name === "light"
            ? "linear-gradient(135deg, var(--primary), var(--bs-light))"
            : "linear-gradient(135deg, var(--primary), var(--bs-backgroundImage))"};
        z-index: -2;
    }

    /* Overlay for contrast */
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.2);
        z-index: -1;
    }

    .down-container {
        height: 10rem;
    }

    #bio {
        background-color: rgba(49, 143, 26, 0.85);
        border-radius: 8px;
        padding: 15px;
    }

    @media (prefers-reduced-motion: no-preference) {
        .hero-img {
            animation: ${Spin} infinite 20s linear;
        }
    }

    @media screen and (min-width: 1180px) {
        &::before {
            background: url(${backgroundImage}) top center fixed no-repeat;
            background-size: cover;
        }
    }

    @media screen and (min-width: 1367px) {
        &::before {
            background: url(${backgroundImage}) center center fixed no-repeat;
            background-size: cover;
        }
    }
`;

export default function Hero() {
    const { name } = useSelector(selectData);
    const { avatar_url, bio } = useSelector(selectData);

    return (
        <StyledAboutMe>
            <Container>
                <Row className="align-items-center text-center mt-5">
                    <Col>
                        <h1 className="display-1 title mb-4">
                            {name}
                        </h1>
                        <img
                            src=/* {profilePic} */{avatar_url}
                            alt="GitHub Avatar"
                            loading="lazy"
                            className="mx-auto shadow rounded-circle my-5"
                            style={{
                                width: "15rem",
                                height: "15rem",
                                border: "2px solid black"
                            }}
                        />
                    </Col>
                    <Col className="d-none d-md-block">
                        <Col className="d-flex flex-column text-center">
                            <Container id="bio">
                                <p>{`${bio} ${moreInfo}`}</p>
                            </Container>
                        </Col>
                    </Col>
                </Row>
            </Container>
        </StyledAboutMe>
    );
}
