import { useAppContext } from "../appContext";
import { Link, useLocation } from "react-router-dom";
// Components
import { Container, Nav, Navbar } from "react-bootstrap";
import { FixedNavSpacer } from "./globalStyledComponents";

export default function SecondaryNavBar() {
    const { pathname } = useLocation();
    const { isExpanded, toggleExpanded } = useAppContext();

    return (
        <>
            <FixedNavSpacer />
            <Navbar
                id="nav"
                collapseOnSelect={true}
                expand="lg"
                expanded={isExpanded}
                bg="dark"
                variant="dark"
                fixed="top"
            >
                <Container>
                    <Navbar.Toggle
                        aria-controls="responsive-navbar-nav"
                        onClick={toggleExpanded}
                    />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav navbarScroll className="me-auto">
                            <Nav.Item>
                                <Link
                                    to="/"
                                    className={pathname === "/" ? "nav-link active" : "nav-link"}
                                    onClick={toggleExpanded}
                                >
                                    About Me
                                </Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link
                                    to="/All-Projects"
                                    className={
                                        pathname === "/All-Projects"
                                            ? "nav-link active"
                                            : "nav-link"
                                    }
                                    onClick={toggleExpanded}
                                >
                                    All Projects
                                </Link>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}
