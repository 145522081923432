import { createContext, useState, useContext } from 'react';

const AppContext = createContext();

const AppProvider = function ({ children }) {
    const [isExpanded, setExpanded] = useState(false);

    const toggleExpanded = () => setExpanded(prevState => !prevState);

    const closeExpanded = function () {
        setTimeout(function () {
            setExpanded(false);
        }, 250);
    };

    return (
        <AppContext.Provider
            value={{
                isExpanded,
                toggleExpanded,
                closeExpanded,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => useContext(AppContext);

export { AppContext, AppProvider };
