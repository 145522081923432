import React from "react";
import styled from "styled-components";
// Icons
import { FaGithub } from "react-icons/fa";
// Components
import { Card } from "react-bootstrap";

const StyledCardComponent = styled.div`
    a {
        text-decoration: none;
        color: white;
    }

    .card-footer {
        border-top: var(--border);

        .card-link {
            text-decoration: none;
            font-size: 1.5rem;
            color: ${({ theme }) => theme.color};

            &:hover {
                color: var(--primary);
            }
        }
    }
`;

export default function StyledCard({ theme, image, title, description, url, height, width, _class, projectLink }) {
    return (
        <StyledCardComponent>
            <Card bg="dark overflow-hidden" className="h-100">
                <Card.Body className="overflow-auto text-center">
                    <a href="#" onClick={() => window.open(projectLink, '_blank')} >
                        <img
                            height={height}
                            width={width}
                            src={image}
                            alt={title}
                            className={_class}
                        />
                        <h3 className="my-3">{title}</h3>
                        <Card.Text>{description}</Card.Text>
                    </a>
                </Card.Body>
                <Card.Footer className="text-center">
                    <Card.Link href={url}>
                        {"View on GitHub "}
                        <FaGithub />
                    </Card.Link>
                </Card.Footer>
            </Card>
        </StyledCardComponent>
    );
}
