import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// Data
import { githubUsername, projectCards } from "../data";

const initialState = {
    error: "",
    isLoading: true,
    data: [],
};

export const url = `https://api.github.com/users/${githubUsername}/repos?per_page=100`;

export const fetchGitHubRepos = createAsyncThunk(
    "allProjects/fetchGitHubRepos",
    async (thunkApi, { rejectWithValue }) => {
        try {
            const response = await fetch(url).then(function (res) {
                if (!res.ok) {
                    throw new Error(res.status);
                }
                return res;
            });
            const data = await response.json();
            return data;
        } catch (err) {
            return rejectWithValue(
                `Error: ${err.message}, check username in data.js (currently ${githubUsername})`
            );
        }
    }
);

export const allProjectsSlice = createSlice({
    name: "allProjects",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchGitHubRepos.pending, (state) => {
                state.isLoading = true;
                state.error = "";
            })
            .addCase(fetchGitHubRepos.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
                projectCards.forEach(card => {
                    state.data.forEach((datum, i) => {
                        if (card.name.toLowerCase() === datum.name.toLowerCase()) {
                            datum.title = card.title;
                            datum.image = card.image;
                            datum.width = card.width;
                            datum.imageHeight = card.imageHeight;
                            datum.imageWidth = card.imageWidth;
                            datum._class = card._class;
                            datum.projectLink = card.projectLink;
                        }
                    });
                });
            })
            .addCase(fetchGitHubRepos.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
                console.log(state.error);
            });
    },
});

export const selectIsLoading = (state) => state.allProjects.isLoading;
export const selectError = (state) => state.allProjects.error;
export const selectData = (state) => state.allProjects.data;

export default allProjectsSlice.reducer;
