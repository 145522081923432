// Skills icons - https://react-icons.github.io/react-icons/
import {
  FaHtml5,
  FaBootstrap,
  FaReact,
  FaCss3,
  FaHardHat
} from "react-icons/fa";
import { SiJavascript, SiExpress, SiSolidity, SiNextdotjs, SiEthereum, SiMongodb, SiIpfs } from "react-icons/si";
import { IoLogoNodejs } from 'react-icons/io';

// Projects Images (add your images to the images directory and import below)
import NFTInDeed from "./images/NFT-in-Deed.png";
import EduChain from "./images/EduChain-Explorer.png";
import profilePic from "./images/profilePic.jpg";


import backgroundImage from "./images/black-hexes.jpeg";
// If you change the import names above then you need to change the export names below
export { profilePic };
export { backgroundImage };

export const githubUsername = "codyrhoten";
export const linkedInUrl = "https://www.linkedin.com/in/codyrhoten/";


/* ******************************* About Me ******************************* */

export const moreInfo =
  "Blockchain Engineer with an aim to contribute to the advancement of web3 and its real-world impact. Certified in the MERN stack, I also use Solidity smart contracts in the back-end in the Ethereum ecosystem. With a couple projects under my belt and more on the way, I enjoy applying decentralization to software engineering in a practical, efficient way. After contributing to the window washing industry for the last 11 years of my career, I’ve embarked on a new path that impacts the worldwide web.";


/* ******************************* Projects *******************************
  List the repo names (string - "your-repo-name") you want to include (they will be sorted alphabetically). If empty, only the first 3 will be included.
*/
export const filteredProjects = ["NFT_In_Deed", "EduChain"];

// Replace the default GitHub image for matching repos below (images imported above - lines 13-14)
export const projectCards = [
  {
    name: "NFT_In_Deed",
    title: "NFT In-deed",
    image: NFTInDeed,
    width: "6",
    imageHeight: "238",
    imageWidth: "80%",
    _class: "mt-2 mx-auto rounded",
    order: 1,
    projectLink: 'https://nftindeed.codyrhoten.com'
  },
  {
    name: "EduChain",
    title: "EduChain",
    image: EduChain,
    width: "4",
    imageHeight: "190",
    imageWidth: "95%",
    _class: "mt-2 mx-auto rounded",
    order: 2,
    projectLink: 'https://educhain.codyrhoten.com'
  }
];


/* ******************************* Skills *******************************
  
  Add or remove skills in the SAME format below, there must be one icon imported above per skill below.
*/
export const skillData = [
  {
    id: 1,
    skill: <SiSolidity className="display-1 mb-2" />,
    name: "Solidity",
  },
  {
    id: 2,
    skill: <SiIpfs className="display-1 mb-2" />,
    name: "IPFS",
  },
  {
    id: 3,
    skill: <FaReact className="display-1 mb-2" />,
    name: "React",
  },
  {
    id: 4,
    skill: <FaHardHat className="display-2 mb-2" />,
    name: "Hardhat",
  },
  {
    id: 5,
    skill: <IoLogoNodejs className="display-1 mb-2" />,
    name: "Node.js",
  },
  {
    id: 6,
    skill: <SiExpress className="display-1 mb-2" />,
    name: "Express",
  },
  {
    id: 7,
    skill: <SiJavascript className="display-5 mb-2" />,
    name: "JavaScript",
  },
  {
    id: 8,
    skill: <FaBootstrap className="display-4 mb-2" />,
    name: "BootStrap",
  },
  {
    id: 9,
    skill: <SiMongodb className="display-4 mb-2" />,
    name: "MongoDB",
  },
  {
    id: 10,
    skill: <SiNextdotjs className="display-5 mb-2" />,
    name: "Next.js",
  },
  {
    id: 11,
    skill: <FaHtml5 className="display-4 mb-2" />,
    name: "HTML5",
  },
  {
    id: 12,
    skill: <FaCss3 className="display-5 mb-2" />,
    name: "CSS3",
  },
];

// Resume link (string - "https://YourResumeUrl") - I am using CloudFront to share my resume (https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/Introduction.html)
export const resume = null;


/* ******************************* Contact Info ******************************* 
  Add your formspree endpoint below.
  https://formspree.io/
*/
export const formspreeUrl = "https://formspree.io/f/myyapjbq";
