import React from "react";
import { Element } from "react-scroll";
import { useSelector } from "react-redux";
import { selectData } from "../pages/homeSlice";
// Components
import NavBar from "../components/NavBar";
import AboutMe from "../components/AboutMe";
import Skills from "../components/Skills";
import Projects from "../components/Projects";
import Contact from "../components/Contact";
import { BackToTop } from "../components/globalStyledComponents";
import Footer from "../components/Footer";

export default function Home() {
    const { name } = useSelector(selectData);

    React.useEffect(
        function () {
            document.title = `${name} | Portfolio`;
        },
        [name]
    );

    return (
        <>
            <Element name={"Home"} id="home">
                <NavBar />
                <AboutMe />
            </Element>
            <main>
                <Projects />
                <Skills />
                <Contact />
            </main>
            <BackToTop home={"Home"} />
            <Footer />
        </>
    );
}
